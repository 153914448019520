import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { formatTime, formatDateWithTimezone } from '@/utils/date'
import {
  convertToMilliseconds,
  toHoursMinutes
} from '@utils/time-tracking-helper'
import typeOf from 'just-typeof'

export default function timeMixin() {
  const i18n = useI18n()
  const locale = computed(() => store.getters['Config/getLocale'])
  const store = useStore()

  const headers = ref([
    {
      name: 'date',
      field: 'date',
      label: i18n.t('pages.timetracking.report.date'),
      minWidth: 93,
      truncate: true,
      formatter: (row) => {
        if (!row.date) return '-'
        return formatDateWithTimezone(row.date, row.timezone)
      }
    },
    {
      name: 'clock_in',
      field: 'clock_in',
      label: i18n.t('pages.timetracking.report.clock_in'),
      align: 'right',
      minWidth: 71,
      truncate: true,
      formatter: (row) =>
        row.clock_in
          ? formatTime(row.clock_in) ||
            new Date(row.clock_in).toLocaleTimeString(locale, {
              hour12: false
            })
          : '–'
    },
    {
      name: 'clock_out',
      field: 'clock_out',
      label: i18n.t('pages.timetracking.report.clock_out'),
      align: 'right',
      minWidth: 83,
      truncate: true,
      formatter: (row) =>
        row.clock_out
          ? formatTime(row.clock_out) ||
            new Date(row.clock_out).toLocaleTimeString(locale, {
              hour12: false
            })
          : '–'
    },
    {
      name: 'total_break',
      field: 'total_break',
      label: i18n.t('pages.timetracking.report.total_break'),
      align: 'right',
      minWidth: 80,
      truncate: true,
      formatter: (row) =>
        typeOf(row.total_break) === 'object' &&
        Object.keys(row.total_break).length
          ? getTotal(row.total_break)
          : '–'
    },
    {
      name: 'total_worked',
      field: 'total_worked',
      label: i18n.t('pages.timetracking.report.total_worked'),
      align: 'right',
      minWidth: 80,
      truncate: true,
      formatter: (row) =>
        typeOf(row.total_worked) === 'object' &&
        Object.keys(row.total_worked).length
          ? getTotal(row.total_worked)
          : '–'
    }
  ])

  function getTotalOverallTime(entries) {
    const totalMillisecs = entries
      .map((time) => convertToMilliseconds(time))
      .reduce((total, millisecs) => total + millisecs, 0)
    const timeObj = toHoursMinutes(totalMillisecs)
    return formatTimeToHoursMinutes(timeObj)
  }

  function formatTimeToHoursMinutes(time) {
    const hours =
      (time.hours ? `${time.hours}` : '0') + i18n.t('common.hour.min')
    const minutes =
      (time.minutes ? `${time.minutes}` : '0') + i18n.t('common.minute.min')
    return `${hours} ${minutes}`
  }

  function getTotal(time) {
    const millisecs = convertToMilliseconds(time)
    const timeObj = toHoursMinutes(millisecs)
    return formatTimeToHoursMinutes(timeObj)
  }

  function getFormattedTime(time) {
    if (!time) return null
    return (
      formatTime(time) ||
      new Date(time).toLocaleTimeString(i18n.locale.value, {
        hour12: false
      })
    )
  }

  function hasBreak(row) {
    return row.total_break.minutes || row.total_break.hours
  }

  return {
    headers,
    locale,
    formatTimeToHoursMinutes,
    getTotalOverallTime,
    getFormattedTime,
    hasBreak
  }
}
