import pick from 'just-pick'
import flush from 'just-flush'
import isEmpty from 'just-is-empty'
import dateFnsSet from 'date-fns/set'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import diffHours from 'date-fns/differenceInHours'
import diffMinutes from 'date-fns/differenceInMinutes'
import overlapping from 'date-fns/areIntervalsOverlapping'

export function convertToMilliseconds(time) {
  const { hours = 0, minutes = 0, seconds = 0, milliseconds = 0 } = time || {}
  return milliseconds + seconds * 1000 + minutes * 60000 + hours * 60000 * 60
}

export function toHoursMinutes(milliseconds) {
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60)
  const hours = Math.floor(milliseconds / (1000 * 60 * 60))

  return { minutes, hours }
}

export function formToEntries(form, staff, entryFields) {
  const result = []

  for (const item of Object.entries(form)) {
    const [field, entry] = item

    if (field === 'day') {
      const cleanedEntry = flush(pick(entry, entryFields))
      result.push({ ...cleanedEntry, type: 'day', staff: staff })
    }

    if (field === 'breaks' && Array.isArray(entry)) {
      for (const breakObj of entry) {
        const cleanedEntry = flush(pick(breakObj, entryFields))
        if (breakObj.started_at) result.push({ ...cleanedEntry, staff: staff })
      }
    }
  }

  return result
}

export function convertEntriesDates(entries, dayDate) {
  return entries.map((entry) => {
    let startedAt = null
    let endedAt = null

    if (entry.started_at) {
      const date = convertTimeToDate(entry.started_at, dayDate)
      if (date) {
        startedAt = new Date(date).toISOString()
      }
    }

    if (entry.ended_at) {
      const date = convertTimeToDate(entry.ended_at, dayDate)
      if (date) {
        endedAt = new Date(date).toISOString()
      }
    }

    return {
      ...entry,
      started_at: startedAt,
      ended_at: endedAt
    }
  })
}

export function convertTimeToDate(time, date) {
  const validDate = new Date(date)
  if (!validDate || time.length < 5) return undefined

  const [hours, minutes] = time.split(':')
  return dateFnsSet(validDate, { hours, minutes })
}

export function validateTimeRange(start, end) {
  if (!start && end) return false
  if (start && start.length === 5 && !end) return true
  return (
    start &&
    start.length === 5 &&
    end &&
    end.length === 5 &&
    Number(start.replace(':', '')) < Number(end.replace(':', ''))
  )
}

export function getTimeDiffText(started_at, ended_at, labels = {}) {
  const startedAtDate = new Date(started_at)
  const endedAtDate = new Date(ended_at)
  const minutes = diffMinutes(endedAtDate, startedAtDate) % 60
  const hours = diffHours(endedAtDate, startedAtDate)
  return [
    hours ? hours + labels.hour : null,
    minutes ? minutes + labels.minute : null
  ].join(' ')
}

export function findBreakOverlap(date, day, breaks) {
  const formattedDates = convertEntriesDates([day, ...breaks], date)
  const clockIn = new Date(formattedDates[0].started_at)
  const clockOut = new Date(formattedDates[0].ended_at)
  const errorTime = formattedDates
    .slice(1)
    .reverse()
    .find(function (element, index, array) {
      if (isEmpty(element.started_at)) return false
      const breakIn = new Date(element.started_at)
      const breakOut = new Date(element.ended_at)
      // Check that the starting point of the break is between the start and end of working day
      // Same with the ending point of the break if exists
      // After check overlap between breaks, no break should share the same range of time (isOverlapping)
      return (
        isBefore(breakIn, clockIn) ||
        isEqual(breakIn, clockIn) ||
        isAfter(breakIn, clockOut) ||
        isEqual(breakIn, clockOut) ||
        (!isEmpty(element.ended_at) &&
          (isAfter(breakOut, clockOut) ||
            isEqual(breakOut, clockOut) ||
            isBefore(breakOut, clockIn) ||
            isEqual(breakOut, clockIn))) ||
        isOverlapping(element, array.slice(index + 1))
      )
    })
  return errorTime
}

export function isOverlapping(currentRange, arrayRangeDatetime) {
  const currentStart = new Date(currentRange.started_at)
  const currentEnd = new Date(currentRange.ended_at)
  for (const datetime of arrayRangeDatetime) {
    //Current break is empty
    if (isEmpty(datetime.started_at)) return false
    //Two open breaks
    if (
      isEmpty(datetime.ended_at) &&
      isEmpty(currentRange.ended_at) &&
      datetime.started_at !== currentRange.started_at
    )
      return false
    const dateStart = new Date(datetime.started_at)
    const dateEnd = new Date(datetime.ended_at)
    if (
      datetime.started_at === currentRange.started_at ||
      datetime.ended_at === currentRange.started_at ||
      datetime.started_at === currentRange.ended_at ||
      datetime.ended_at === currentRange.ended_at ||
      (!isEmpty(datetime.ended_at) &&
        !isEmpty(currentRange.ended_at) &&
        overlapping(
          {
            start: dateStart,
            end: dateEnd
          },
          {
            start: currentStart,
            end: currentEnd
          }
        ))
    ) {
      return true
    }
  }
  return false
}

export function isDateRangeInsideDateRange(
  outsideClockIn,
  outsideClockOut,
  insideClockIn,
  insideClockOut
) {
  return (
    isAfter(insideClockIn, outsideClockIn) &&
    isBefore(insideClockIn, outsideClockOut) &&
    isAfter(insideClockOut, outsideClockIn) &&
    isBefore(insideClockOut, outsideClockOut)
  )
}
